import Table from '../NewTable';
import React from 'react';

const SuccessTable = () => {
  return (
    <Table
      data={[
        {
          stepNumber: 1,
          steps: (
            <div>
              <p>
                Einreichen deiner IT-Initiative über die Anwendung{' '}
                <a
                  href="https://meine-it.hansewerk.com/meine-it-initiative"
                  target="_blank"
                  className="underline text-blue-700 font-medium"
                >
                  Meine IT-Initiative:
                </a>
              </p>
              <ul className="list-disc ml-5">
                <li>Beschreibe deine Herausforderung und Initiative</li>
                <li>Lade alle projektrelevanten Dokumente hoch</li>
                <li>Benenne dein Budget (falls bekannt)</li>
              </ul>
            </div>
          ),
          contactPerson: (
            <div>
              <p>Andrea Welp</p>
              <p>Marvin Jensen</p>
            </div>
          ),
        },
        {
          stepNumber: 2,
          steps: (
            <div>
              <p>Bewertung und Freigabe deiner IT-Initiative durch TD:</p>
              <ul className="list-disc ml-5">
                <li>
                  Es kommt ein Mitarbeiter von TD auf dich zu, um technische und
                  wirtschaftliche Fragen zu klären
                </li>
                <li>Deine eingereichte IT-Initiative wird bei TD bewertet</li>
                <li>Das Budget für deine IT-Initiative wird bewertet </li>
              </ul>
            </div>
          ),
          contactPerson: (
            <div>
              <p>Andrea Welp</p>
              <p>Marvin Jensen</p>
            </div>
          ),
        },
        {
          stepNumber: 3,
          steps: (
            <div>
              <p>
                Freigabe deiner IT-Initiative durch das
                Informationssicherheitsmanagement:
              </p>
              <ul className="list-disc ml-5">
                <li>Vereinbare einen Termin mit dem ISM </li>
                <li>
                  SBK erstellen, prüfen und freigeben durch Klaus Kempelmann und
                  Andrea Welp
                </li>
              </ul>
            </div>
          ),
          contactPerson: (
            <div>
              <p>Klaus Kempelmann</p>
              <p>Andrea Welp</p>
            </div>
          ),
        },
        {
          stepNumber: 4,
          steps: (
            <div>
              <p>Freigabe deiner IT-Initiative durch den Datenschutz:</p>
              <ul className="list-disc ml-5">
                <li>Vereinbare einen Termin mit dem Datenschutz</li>
                <li>
                  Risikobewertung, Checkliste privacy by design and default,
                  Sperr- und Löschmatrix, Eintrag PrIME mit dem Datenschutz
                </li>
              </ul>
            </div>
          ),
          contactPerson: (
            <div>
              <p>
                Deinen Ansprechpartner findest du{' '}
                <a
                  href={
                    'https://connect.eon.com/pages/haw-datenschutz/apps/content/datenschutzkoordinatoren'
                  }
                  target="_blank"
                  className="underline text-blue-700 font-semibold"
                >
                  hier
                </a>
              </p>
            </div>
          ),
        },
        {
          stepNumber: 5,
          steps: (
            <div>
              <p>
                Freigabe deiner IT-Initiative durch die
                Mitbestimmung/Betriebsrat (GBR):
              </p>
              <ul className="list-disc ml-5">
                <li>
                  Fülle die{' '}
                  <a
                    href={
                      'https://connect.eon.com/pages/pg-haw-it-themen/apps/wiki/einbindung-br-dsb-isms/list/view/ea2be170-14b8-4d3f-a459-ae81d07618c1'
                    }
                    target="_blank"
                    className="underline text-blue-700 font-medium"
                  >
                    GBR-Erstanzeige
                  </a>{' '}
                  aus und versende diese über{' '}
                  <a
                    href={'https://meine-it.hansewerk.com/meine-it-initiative'}
                    target="_blank"
                    className="underline text-blue-700 font-medium"
                  >
                    Meine IT-Initiative
                  </a>{' '}
                  an den Betriebsrat
                </li>
                <li>Ggf. Rücksprache mit dem GBR</li>
              </ul>
            </div>
          ),
          contactPerson: (
            <div>
              <p>Kirsten Löbzien</p>
              <p>Arbeitgebervertreterin:</p>
              <p>Angela Sandmann</p>
            </div>
          ),
        },
        {
          stepNumber: 6,
          steps: (
            <div>
              <p>
                Freigabe deiner IT-Initiative durch den Informations- und
                Kommunikationsausschuss (IuK):
              </p>
              <ul className="list-disc ml-5">
                <li>
                  Ausfüllen der Erstanzeige über den{' '}
                  <a
                    href={
                      'https://bpm.eon.com/erstanzeige'
                    }
                    target="_blank"
                    className="underline text-blue-700 font-medium"
                  >
                    IuK-Mitbestimmungsprozess
                  </a>
                </li>
                <li>Vereinbare einen Termin mit IuK</li>
              </ul>
            </div>
          ),
          contactPerson: (
            <div>
              <p>Kirsten Löbzien</p>
            </div>
          ),
        },
        {
          stepNumber: 7,
          steps: (
            <div>
              <p>
                Ggf. Freigabe deiner IT-Initiative durch den Aus- und
                Fortbildungsausschuss
              </p>
            </div>
          ),
          contactPerson: (
            <div>
              <p>Dennis Rechner</p>
            </div>
          ),
        },
      ]}
      columns={[
        { label: '', render: data => <p>{data.stepNumber}</p> },
        {
          label: 'Schritte',
          render: data => <div>{data.steps}</div>,
        },
        {
          label: 'Ansprechpartner',
          render: data => <div>{data.contactPerson}</div>,
        },
      ]}
    />
  );
};

export default SuccessTable;
