import React, { ChangeEvent, useState, useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import uploadIcon from '../../assets/images/uploadIcon_blue.png';
import useTypedForm from 'components/common/Form/components';
import { Button } from 'stories/Button';
import { DocumentTable } from '../../components/myItIdea/components/DocumentTable';
import { IDocument } from '../../components/myItIdea/components/DocumentRow';
import { StepProps } from '.';
import { Breadcrumb } from 'components/Breadcrumbs/Breadcrumb';
import { Prompt, useLocation } from 'react-router-dom';
import {
  useCreateProjectShortHistoryMutation,
  useRemoveProjectDocumentMutation,
} from 'graphql/codegen/graphql';
import { debounce } from 'lodash';
import { useAppContext } from '../../AppContext';
export type FormData = {
  documents: IDocument[];
};

const Documents = ({
  values,
  onSubmit,
  onPrev,
  documents,
  projectId,
}: StepProps<FormData>) => {
  const { t } = useTranslation();
  const [removeProjectDocumentMutation] = useRemoveProjectDocumentMutation({
    refetchQueries: ['getProjectDocuments'],
  });
  const [createProjectShortHistory] = useCreateProjectShortHistoryMutation();
  const { Form } = useTypedForm<FormData>();
  const { user } = useAppContext();
  const location = useLocation();
  const tdReporting = location.pathname.includes('general/documents');
  const [docs, setDocs] = useState<IDocument[]>([] as IDocument[]);
  const [criteriaStatus, setCriteriaStatus] = useState(true);
  const [showError, setShowError] = useState(true);
  const [preventReload, setPreventReload] = useState<boolean>(false);
  const [uploadError, setUploadError] = useState(false);

  const handleDocumentSelect = (e: ChangeEvent<HTMLInputElement>) => {
    setUploadError(false);
    const newDocs: IDocument[] = [...docs];
    Array.from(e.target.files || []).forEach(f => {
      const doesExist = newDocs.some(n => n.name === f.name);
      if (!doesExist) {
        newDocs?.push({
          id: uuidv4(),
          description: '',
          file: f,
        });
      } else {
        setUploadError(true);
      }
      setDocs(newDocs);
    });
  };

  const handleDelete = (doc: IDocument) => {
    if (window.confirm(t('tdReporting.deleteDocs'))) {
      const newDocs = docs.filter(d => d !== doc);
      if (doc.id && tdReporting) {
        removeProjectDocumentMutation({
          variables: {
            where: {
              id: doc.id,
            },
          },
        });
      }

      createProjectShortHistory({
        variables: {
          data: {
            project: {
              connect: {
                id: projectId,
              },
            },
            action: t`tdReporting.subpages.documents`,
            createdBy: user?.kid,
          },
        },
      });
      setDocs(newDocs);
    }
  };

  const handleReUpload = () => {
    console.log('handle re upload');
  };

  const handleCriteriaStatus = (status: boolean) => {
    setCriteriaStatus(status);
  };

  const handleDescChange = (text: string, index: number) => {
    let newDocs = docs.map(d => {
      return { ...d, description: d.description };
    });
    newDocs[index].description = text;
    if (tdReporting) {
      setShowError(true);
      if (text.length) {
        debouncedSetDocument(newDocs);
      }
    } else {
      setDocs(newDocs);
    }
  };

  window.onbeforeunload = event => {
    const e = event || window.event;
    if (
      docs.some(d => {
        return d.description === '';
      })
    ) {
      e.preventDefault();
      if (e) {
        e.returnValue = '';
      }
      return '';
    }
  };

  const debouncedSetDocument = useRef(
    debounce(docs => {
      setDocs(docs);
      onSubmit({ documents: docs });
    }, 1000),
  ).current;

  const handleOnSubmit = () => {
    onSubmit({ documents: docs });
  };
  useEffect(() => {
    if (documents) {
      setDocs(documents);
    }
  }, [documents]);

  useEffect(() => {
    setPreventReload(
      docs.some(d => {
        return d.description === '';
      }),
    );
  }, [docs]);

  return (
    <div className="flex flex-col flex-1 w-full">
      <Prompt
        when={preventReload}
        message={t('tdReporting.unsavedDataMessage')}
      />
      <Breadcrumb label="Dokumente" route="neue-it-initiative/documents" />
      <p className="text-base">{t`myItIdea.documents.description`}</p>
      <Form
        onSubmit={() => ''}
        defaultValues={values}
        mode="onChange"
        className="flex flex-col h-full"
      >
        <div className="flex flex-col flex-1 w-full space-y-5">
          {tdReporting ?? (
            <p className="text-base">
              <Trans i18nKey="myItIdea.documents.description" />
            </p>
          )}
          <div className="flex w-fit pt-8">
            <label
              className="h-10 storybook-button--label storybook-button--secondary"
              htmlFor="select-document"
            >
              <div className="flex mx-6">
                <img className="h-4 mr-2" src={uploadIcon} alt="upload icon" />
                {t('myItIdea.documents.upload')}
              </div>
            </label>
            <div>
              <input
                className="hidden"
                onChange={handleDocumentSelect}
                type="file"
                id="select-document"
                multiple
              />
            </div>
          </div>
          <div className="w-full">
            <DocumentTable
              docs={docs}
              markError={showError}
              onDelete={handleDelete}
              onReupload={handleReUpload}
              handleCriteriaStatus={handleCriteriaStatus}
              handleDescChange={handleDescChange}
            />
          </div>
        </div>

        <div className="flex justify-end flex-grow-0 pt-5 space-x-7">
          {onPrev && (
            <Button onClick={onPrev} theme="secondary">
              {t('myItIdea.actions.prev')}
            </Button>
          )}
          {!tdReporting && (
            <Button onClick={handleOnSubmit} disabled={!criteriaStatus}>
              {t('myItIdea.actions.next')}
            </Button>
          )}
        </div>
      </Form>
    </div>
  );
};
export default Documents;
